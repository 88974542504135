<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card__title">
        <p>Список курьеров</p>
        <v-text-field
          v-model="findText"
          label="Поиск..."
          class="search-input"
          hint="Крестик для сброса результатов поиска"
          @keyup.enter="findCourier()"
        >
          <v-btn
            v-if="findText"
            slot="append"
            color="red"
            icon
            @click="clearInput()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn slot="append" color="primary" icon @click="findCourier()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-text-field>
      </div>
      <div class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div
              v-for="item in items.head"
              :key="item.id"
              class="head__item"
              :class="(item.id === 1 || item.id === 5) && 'image'"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.id" class="value">
            <div
              class="value__item_image"
              :style="
                item.image
                  ? { 'background-image': `url(${item.image.fileUrl})` }
                  : ''
              "
            >
              <p v-if="!item.image">Нет фото</p>
            </div>
            <div class="value__item">
              {{ item.username }}
            </div>
            <div class="value__item">
              {{ item.phone }}
            </div>
            <div class="value__item">
              <v-select
                v-model="item.courierType"
                :items="STATE.allTypes"
                item-value="id"
                item-text="name"
                @change="changeType(item.id, $event)"
              ></v-select>
            </div>
            <div class="value__item">
              <v-select
                :value="item.courierDeliveryZoneId"
                :items="deliveryZones"
                item-value="id"
                item-text="name"
                :label="item.courierDeliveryZoneId ? '' : 'Не назначена'"
                @change="setZone($event, item.id)"
              ></v-select>
            </div>
            <div class="value__item_image">
              <v-btn icon large color="primary" @click="openModal(item)"
                ><v-icon>mdi-information-slab-circle</v-icon></v-btn
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    large
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      zonesModal = true;
                      courierInfo = item;
                    "
                  >
                    <v-icon>mdi-account-cog</v-icon>
                  </v-btn>
                </template>
                <span>Филиалы курьеров</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      deletedCouriersZoneId = item.id;
                      show = true;
                    "
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Удалить зону</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Диалоговое для вывода подробной информации о курьере -->
    <v-dialog v-model="modal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-text class="modal__text">
          <div class="modal__text_image">
            <v-img
              v-if="courierInfo.image"
              max-height="200"
              max-width="200"
              :alt="courierInfo.image.alt"
              :src="courierInfo.image.fileUrl"
            ></v-img>
            <p v-else>Нет фото</p>
          </div>
          <div class="modal__text_info">
            <div class="modal__item">
              <span class="font-weight-black">Имя: </span
              >{{ courierInfo.username }}
            </div>
            <div class="modal__item">
              <span class="font-weight-black">Телефон:</span>
              {{ courierInfo.phone }}
            </div>
            <div v-if="courierInfo.courierType" class="modal__item">
              <span class="font-weight-black">Тип: </span
              >{{ courierInfo.courierType.name }}
            </div>
            <div class="modal__item">
              <span class="font-weight-black">Почта: </span>
              {{ courierInfo.email ? courierInfo.email : "не указана" }}
            </div>
            <div class="modal__item">
              <span class="font-weight-black">Зона доставки: </span>
              {{
                courierInfo.courierDeliveryZoneId
                  ? deliveryZones.find(
                      (zone) => zone.id === courierInfo.courierDeliveryZoneId
                    ).name
                  : "не указана"
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn right @click="modal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Диалоговое для назначения отдельных филиалов -->
    <v-dialog v-model="zonesModal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title
          >Назначить курьеру <br />
          филиалы для доставки</v-card-title
        >
        <v-card-text class="modal__text">
          <v-select
            v-model="currentDepartmentId"
            :items="departments"
            multiple
            chips
            item-value="id"
            item-text="name"
            label="Филиал доставки"
          ></v-select>
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn @click="zonesModal = false">Отмена</v-btn>
          <v-btn color="primary" @click="setFilial()">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteDeliveryZone()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      loading: false,
      modal: false,
      show: false,
      zonesModal: false,
      findText: "",
      courierInfo: {
        image: null,
        email: null,
        username: "",
        phone: "",
        courierType: { name: "" },
        courierDeliveryZone: { id: 0 },
      },
      types: [],
      items: {
        head: [
          { id: 1, name: "Фото" },
          { id: 2, name: "Имя" },
          { id: 3, name: "Телефон" },
          { id: 4, name: "Тип курьера" },
          { id: 6, name: "Зона доставки" },
          { id: 5, name: "Кнопки" },
        ],
        body: [],
      },
      deliveryZones: [],
      departments: [],
      currentDepartmentId: [],
      deletedCouriersZoneId: 0,
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Couriers/STATE",
    }),
  },
  watch: {
    zonesModal(value) {
      if (!value) {
        this.currentDepartmentId = [];
      }
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      getAllCourierTypes: "Couriers/GET_ALL_COIRIERS_TYPES",
      getCouriersList: "Couriers/GET_ALL_COIRIERS",
      changeCourierType: "Couriers/ADD_COURIER_TYPE",
      getDepartments: "Couriers/GET_DELIVERY_ZONE_DEPARTMENTS",
      getDeliveryZone: "Couriers/GET_ALL_DELIVERY_ZONES",
    }),
    //Подгружает список курьеров
    async setValues() {
      this.loading = true;
      await this.getCouriersList();
      await this.getAllCourierTypes();
      let dep = await this.getDepartments();
      // выводить только неудаленные филиалы, приходят с бэка все
      this.departments = dep.filter((item) => item.delete === false);
      this.items.body = this.STATE.allCouriers;
      if (this.STATE.allDeliveryZones.length === 0) {
        await this.getDeliveryZone();
      }
      this.deliveryZones = this.STATE.allDeliveryZones;
      this.loading = false;
    },
    async changeType(courierId, typeId) {
      let payload = { courierId, typeId };
      this.loading = true;
      let response = await this.changeCourierType(payload);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        await this.getCouriersList();
        this.items.body = this.STATE.allCouriers;
      }
      this.loading = false;
    },
    //Открывает модалку с данными из выбранного типа
    openModal(courierObj) {
      this.courierInfo = courierObj;
      this.modal = true;
    },
    findCourier() {
      this.items.body = this.STATE.allCouriers.filter((item) =>
        item.username.toLowerCase().includes(this.findText.toLowerCase())
      );
    },
    clearInput() {
      this.items.body = this.STATE.allCouriers;
      this.findText = "";
    },
    async setZone(zoneId, userId) {
      this.loading = true;
      let response = await api.Couriers.setDeliveryZoneToCourier({
        userId,
        zoneId,
      });
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        await this.getCouriersList();
        this.items.body = this.STATE.allCouriers;
      }
      this.loading = false;
    },
    async setFilial() {
      if (this.currentDepartmentId.length > 0) {
        let body = {
          userId: this.courierInfo.id,
          departmentIds: this.currentDepartmentId,
        };
        let response = await api.Couriers.setSelfZonedDeliveryZoneToCourier(
          body
        );
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Филиал(ы) для курьера назначены", true);
        }
        this.zonesModal = false;
        this.currentDepartmentId = [];
        this.loading = false;
      }
    },
    async deleteDeliveryZone() {
      this.loading = true;
      let response = await api.Couriers.deleteCouriersDeliveryZone(
        this.deletedCouriersZoneId
      );
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Зона доставки для курьера удалена", true);
        await this.getCouriersList();
        this.items.body = this.STATE.allCouriers;
      }
      this.show = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          font-weight: 500;
          width: 20%;
          text-align: center;
        }
      }
      .image {
        width: 70px;
      }
      .value {
        display: flex;
        justify-content: space-between;
        &__item {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          &_image {
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            p {
              margin: 0;
            }
          }
        }
        &__btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__item {
    text-align: left;
  }
  &__text {
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 0 !important;
    p {
      margin: 0;
    }
    &_info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
.search-input {
  max-width: 50%;
}
</style>
>
